<template>
    <v-container fluid  class="pa-0">
      <v-row class="pt-4 mx-0"> 
        <v-col
          cols="12"
          class="d-flex justify-center align-center flex-column"
          align="center"
          justify="center"
        >
          <h3 class="secondary--text text-center">
            AVISO DE PRIVACIDAD SIMPLIFICADO 
          </h3>
          <h4 class="secondary--text text-center">
            APP SIN VIOLENCIA
          </h4>          
        </v-col>
        <v-col cols="12">
            <h5>
                GRUPO EMPRESARIAL TIDINGO S.A DE C.V, es el responsable del uso y protección de sus datos personales común mente conocido por su nombre comercial “GRUPO EMPRESARIAL TIDINGO”, y al respecto le informamos lo siguiente:                
            </h5>
            <h5>
                Los datos personales se refieren a la información concerniente a una persona física identificada o identificable, y por datos personales sensibles, aquellos que afecten a la esfera más íntima de su titular o cuya utilización indebida pueda dar origen a discriminación o conlleve un riesgo grave para éste.
            </h5>            
        </v-col>
        <v-col cols="12">
          <h4 class="secondary--text text-center">
            EL DOMICILIO DEL RESPONSABLE.
          </h4>   
            <h5>
                Con domicilio en Calle Libramiento Carretero #1050, Colonia Nuevas Palomas, Tepic, Nayarit, México C.P 63195, con datos de contacto para el público en general: soporte@gremti.com, número de teléfono (332) 832 68 70 y página de internet: www.gremti.com.
            </h5>            
        </v-col>   
        <v-col cols="12">
          <h4 class="secondary--text text-center">
            ¿PARA QUÉ FINES UTILIZAREMOS SUS DATOS PERSONALES? 
          </h4>   
            <h5>
                Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades que son necesarias para el servicio que solicita:
            </h5>   
            <ul> 
                <li><h5>
                        Registro de clientes y proveedores.
                    </h5></li>        
                <li><h5>
                        Atender los requerimientos de compras de las Unidades de Negocio validando sus solicitudes con los convenios y contratos preestablecidos.
                    </h5></li>        
                <li><h5>
                        Solicitar cotización a los proveedores.
                    </h5></li>        
                <li><h5>
                        Generar órdenes de compras y asegurar su validación.
                    </h5></li>        
                <li><h5>
                        Confirmar el envío recepción y asegurar la provisión de las órdenes de compra.
                    </h5></li>        
                <li><h5>
                        Cumplir con los contratos y convenios.
                    </h5></li>    
            </ul>
            <h5>
                Sus datos personales no son utilizados para <strong>fines distintos</strong> a los señalados en el presente aviso de privacidad, ni <strong>finalidades secundarias o accesorias</strong> para las cuáles se necesita su consentimiento expreso. 
            </h5>                                                                                                                                        
        </v-col>                       
      </v-row>
    </v-container>
</template>

<script>


export default {
name: "Privacy",
  data: () => ({
  }),
};
</script>
