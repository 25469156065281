<template>
  <!-- <video src="/tec_gob.mp4" autoplay poster="posterimage.jpg" controls>
    Tu navegador no admite el elemento <code>video</code>.
  </video> -->
  <v-img
    height="80vh"
    src="/header.jpg"
    gradient="to top right, rgba(0,0,0,.5), rgba(0,0,0,.5)"
    style="position: relative"
  >
    <v-row style="height: 100%" class="mx-0">
      <v-col
        cols="12"
        sm="12"
        md="8"
        offset-md="2"
        class="d-flex justify-center align-center flex-column px-4"
      >
        <h1 class="white--text mb-5 font-weight-bold text-center">
          Soluciones tecnológicas para tu empresa y gobierno
        </h1>
        <v-btn outlined color="white" rounded large v-scroll-to="'#us'">
          CONÓCENOS
          <svg-icon type="mdi" :path="path"></svg-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-img>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiArrowDown } from "@mdi/js";
export default {
  name: "HelloWorld",
  props: ["imgSrc"],
  components: {
    SvgIcon,
  },
  data: () => ({
    //
    path: mdiArrowDown,
  }),
};
</script>
